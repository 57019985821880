<template>
  <transition name="fade">
    <div
      v-if="visible"
      :class="alertClasses"
      class="mb-2 rounded-lg shadow-lg"
      role="alert"
      style="margin: 0 auto; max-width: 512px"
    >
      <svg
        v-if="icon"
        :class="iconColor"
        class="flex-shrink-0 w-5 h-5 mr-3"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          v-if="type === 'info'"
          fill-rule="evenodd"
          d="M8.257 3.099c.366-.446.996-.446 1.362 0l6.406 7.801c.365.445.334 1.107-.069 1.552a1.116 1.116 0 01-1.576.069L10 5.897l-4.38 5.324a1.116 1.116 0 01-1.576-.069 1.062 1.062 0 01-.069-1.552l6.406-7.801zM10 13a1 1 0 110 2 1 1 0 010-2z"
          clip-rule="evenodd"
        />
        <!-- Add similar paths for other types if needed -->
      </svg>
      <div class="text-sm font-medium flex-grow">
        {{ message }}
      </div>
      <button
        class="ml-auto -mx-1.5 -my-1.5 rounded-lg p-1.5 focus:ring-2 inline-flex h-8 w-8"
        aria-label="Close"
        @click="dismissAlert"
      >
        <svg
          class="w-4 h-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
  </transition>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed } from 'vue'

const props = defineProps({
  visible: Boolean,
  duration: Number,
  type: {
    type: String,
    default: 'info', // 'success', 'warning', 'error'
  },
  message: String,
})

const alertClasses = computed(() => {
  const baseClasses = 'flex items-center p-4 mb-4 rounded-lg text-white'
  switch (props.type) {
    case 'success':
      return `${baseClasses} bg-primary/90`
    case 'error':
      return `${baseClasses} bg-red-500/90`
    case 'warning':
      return `${baseClasses} bg-yellow-500/90`
    default:
      return `${baseClasses} bg-secondary/90`
  }
})

const iconColor = computed(() => {
  switch (props.type) {
    case 'success':
      return 'text-green-700'
    case 'error':
      return 'text-red-700'
    case 'warning':
      return 'text-yellow-700'
    default:
      return 'text-blue-700'
  }
})

const emit = defineEmits(['update:visible'])

function dismissAlert() {
  emit('update:visible', false)
}

onMounted(() => {
  if (props.duration) {
    setTimeout(() => {
      dismissAlert()
    }, props.duration * 1000)
  }
})
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
